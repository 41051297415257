import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  jobs: [],
  job: [],
  jobView: undefined,
  jobinformation: [],
  jobspecification: [],
  jobdescription: [],
  jobapply: [],
  loadingAd: false,
  jobsFilter: [],
});

const getters = {
  allJob: (state) => state.jobs,
  allSingleJob: (state) => state.job,

  allJobInformation: (state) => state.jobinformation,
  allJobSpecification: (state) => state.jobspecification,
  allJobDescription: (state) => state.jobdescription,

  allSingleJobInformation: (state) => state.jobinformation,
  allSingleJobSecification: (state) => state.jobspecification,
  allSingleJobDescription: (state) => state.jobdescription,

  applyforthejob: (state) => state.jobapply,

  allloadingAd: (state) => state.loadingAd,

  allFilterJob: (state) => state.jobsFilter,
  allJobView: (state) => state.jobView,
};

const actions = {
  async filterJob({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "job/filterjobfront", data);
      commit("setFilterJob", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async applyforjob({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(
        baseUrl + "job/applyjob",
        { slug: data },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setJobAppy", response.data);
    } catch (error) {
      commit("setJobAppy", error.response.data);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async getAllJob({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(baseUrl + "job/joball", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setJob", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async getAllJobFilter({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(baseUrl + "job/filterdashjob", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setJob", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async singleJobInformationAdmin({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "job/jobinformationAdmin/" + data
      );
      commit("setJobInformation", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async singleJobInformation({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "job/jobinformation/" + data);
      commit("setJobInformation", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async singleJobSpecification({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "job/jobspecification/" + data
      );
      commit("setJobSpecification", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async singleJobDescription({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "job/jobdescription/" + data);
      commit("setJobDescription", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async createJobInformation({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(
        baseUrl + "job/createJobInformation",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setJobInformation", response);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async createJobSpecification({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(
        baseUrl + "job/createJobSpecification",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setJobSpecification", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async createJobDescription({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(
        baseUrl + "job/createJobDescription",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      router.push("/dashboard/jobs");
      commit("setJobDescription", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async updateJobStatus({ commit }, data) {
    try {
      const response = await Axios.put(baseUrl + "job/updateStatus", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setJob", response.data);
      this.dispatch("getAllJob");
    } catch (error) {
      console.log(error);
    }
  },

  async getSingleJob({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.get(baseUrl + "job/single/job/" + data);
      commit("setSingleJob", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async getSingleJobView({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "job/singleview/job/" + data);
      commit("setSingleJobView", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async selectedResume({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "job/shortListResume", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSingleJobView", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async selectedCv({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "job/shortListCv", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSingleJobView", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getSelectedCView({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "job/singleResume/" + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSingleJobView", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadingAdFunc({ commit }) {
    commit("loadingAdSet", true);
  },
  async loadingNotAdFunc({ commit }) {
    commit("loadingNotAdSet", false);
  },
};

const mutations = {
  setJob: (state, data) => (state.jobs = data),
  setFilterJob: (state, data) => (state.jobsFilter = data),
  setJobAppy: (state, data) => (state.jobapply = data),
  setSingleJob: (state, data) => (state.job = data),
  setSingleJobView: (state, data) => (state.jobView = data),
  setJobInformation: (state, data) => (state.jobinformation = data),
  setJobSpecification: (state, data) => (state.jobspecification = data),
  setJobDescription: (state, data) => (state.jobdescription = data),
  loadingAdSet: (state, loading) => (state.loadingAd = loading),
  loadingNotAdSet: (state, loading) => (state.loadingAd = loading),
};

export default { state, getters, actions, mutations };
