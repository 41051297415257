import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  logins: [],
  registrations: [],
  verifications: [],
  loadingAuth: false,
  users: [],
  companies: [],
  company: [],
  menus: [],
  menu: [],
  nepalpayment: undefined,
  paymentToken: undefined,
  imepaymentToken: undefined,
  imepaymentStatus: undefined,
});

const getters = {
  allLogin: (state) => state.logins,
  allRegister: (state) => state.registrations,
  allVerify: (state) => state.verifications,
  allLoadingAuth: (state) => state.loadingAuth,
  allUser: (state) => state.users,
  allCompanyUser: (state) => state.companies,
  allSingleCompany: (state) => state.company,
  allMenus: (state) => state.menus,
  allMenu: (state) => state.menu,
  allNepalPayment: (state) => state.nepalpayment,
  allPaymentToken: (state) => state.paymentToken,
  allImePaymentToken: (state) => state.imepaymentToken,
  allImePaymentStatus: (state) => state.imepaymentStatus,
};

const actions = {
  async menuAssign({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "access/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if (response.status === 200) {
        commit("setMenuAccess", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setMenuAccess", error.response);
    }
    this.dispatch("loadingNotAuth");
  },
  async login({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/login", data);
      if (response.status === 200) {
        router.push("/dashboard");
        localStorage.setItem("token", JSON.stringify(response.data));
        commit("setLoginData", response.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 443) {
        router.push("/verify");
      }
      commit("setLoginData", error.response);
      setTimeout(() => {
        commit("setLoginData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async forget({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/forgetPassword", data);
      if (response.status === 200) {
        localStorage.setItem("emailAuth", data.email);
        router.push("/setpassword");
        commit("setLoginData", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setLoginData", error.response);
      setTimeout(() => {
        commit("setLoginData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async verify({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.put(baseUrl + "user/verify", data);
      if (response.status === 200) {
        commit("setVerifyData", response.data);
        router.push("/login");
        localStorage.removeItem("emailAuth");
      }
    } catch (error) {
      console.log(error);
      commit("setVerifyData", error.response);
      setTimeout(() => {
        commit("setVerifyData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async setNewPassword({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.put(baseUrl + "user/setNewPassword", data);
      if (response.status === 200) {
        commit("setVerifyData", response.data);
        router.push("/login");
        localStorage.removeItem("emailAuth");
      }
    } catch (error) {
      console.log(error);
      commit("setVerifyData", error.response);
      setTimeout(() => {
        commit("setVerifyData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async register({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/register", data);

      if (response.status === 201) {
        router.push("/verify");

        localStorage.setItem("emailAuth", data.email);
      }
      commit("setRegisterData", { data: response.data.results.message });
    } catch (error) {
      commit("setRegisterData", error.response);
      setTimeout(() => {
        commit("setRegisterData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async registerByAdmin({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/registerByAdmin", data);

      if (response.status === 201) {
        router.push("/dashboard/userlist");

        localStorage.setItem("emailAuth", data.email);
      }
      commit("setRegisterData", { data: response.data.results.message });
    } catch (error) {
      commit("setRegisterData", error.response);
      setTimeout(() => {
        commit("setRegisterData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async logout({ commit }) {
    try {
      if (process.browser) {
        if (localStorage.getItem("token")) {
          router.push("/");
          localStorage.clear();
        }
      }
    } catch (error) {
      console.log(error);
    }

    commit("setLoginData", []);
  },
  async getUsers({ commit }, data) {
    try {
      this.dispatch("loadingAuth");
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/users", data, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
        if (response.status === 200) {
          commit("setUserData", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAuth");
  },
  async getCompanylistDash({ commit }, data) {
    try {
      this.dispatch("loadingAuth");
      if (process.browser) {
        const response = await Axios.post(baseUrl + "user/company", data, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
        if (response.status === 200) {
          commit("setCompanyData", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAuth");
  },
  async loadingAuth({ commit }) {
    commit("loadingAuthSet", true);
  },
  async loadingNotAuth({ commit }) {
    commit("loadingNotAuthSet", false);
  },
  async fillUserData({ commit }) {
    if (localStorage.getItem("token")) {
      commit("setLoginData", JSON.parse(localStorage.getItem("token")));
    }
  },
  async getUserForMenu({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "user/dashboardaccess", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setUserData", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getMenus({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "access/menus", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setMenu", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleCompanyUser({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "user/companysingle", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSingleCompany", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async wishlistAction({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "job/wishlist", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSingleCompany", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async putCompanyPaymentStatus({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "user/updatePaymentStatus",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setCompanyData", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getNepalPayment({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "access/nepalpaymentsignature",
        data
      );
      commit("setNepalPayment", response);
      commit("setPaymentToken", undefined);
    } catch (error) {
      console.log(error);
      commit("setNepalPayment", error.response);
    }
  },
  async geImePayment({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "access/imetoken",
        data
      );
      commit("setImePaymentToken", response);
    } catch (error) {
      console.log(error);
      commit("setImePaymentToken", error.response);
    }
  },
  async geImePaymentStatus({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "access/imecheckpaymentstatus",
        data
      );
      commit("setImePaymentStatus", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getPaymentToken({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "access/getTokenDetail/" + data
      );
      commit("setPaymentToken", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async resetState({ commit }) {
    commit("setPaymentToken", undefined);
  },
};

const mutations = {
  setLoginData: (state, user) => (state.logins = user),
  setUserData: (state, user) => (state.users = user),
  setMenu: (state, user) => (state.menus = user),
  setMenuAccess: (state, user) => (state.menu = user),
  setCompanyData: (state, user) => (state.companies = user),
  setRegisterData: (state, user) => (state.registrations = user),
  setVerifyData: (state, user) => (state.verifications = user),
  loadingAuthSet: (state, loading) => (state.loadingAuth = loading),
  loadingNotAuthSet: (state, loading) => (state.loadingAuth = loading),
  setSingleCompany: (state, loading) => (state.company = loading),
  setNepalPayment: (state, data) => (state.nepalpayment = data),
  setPaymentToken: (state, data) => (state.paymentToken = data),
  setImePaymentToken: (state, data) => (state.imepaymentToken = data),
  setImePaymentStatus: (state, data) => (state.imepaymentStatus = data),
};

export default { state, getters, actions, mutations };
